<template>
  <div class="auth-wrapper auth-v1 px-2">
    <b-link class="brand-logo">
      <img src="@/assets/images/logo/logo-dialog.png" alt="">
    </b-link>
    <b-card-text class="text-center logo-text">
      <span>Hardware Configuration</span>
    </b-card-text>
    <div class="auth-inner py-2">
      <!-- Register v1 -->
      <b-card class="mb-0">

        <!-- form -->
        <validation-observer ref="registerForm">
          <b-row>
            <b-form
              class="col-md-6 auth-register-form"
              @submit.prevent="validationForm"
            >
              <b-form-group
                label="Email*"
                label-for="email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="email"
                    v-model="regEmail"
                    :state="errors.length > 0 ? false:null"
                    name="register-email"
                    placeholder="Diogo@diogo.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- password -->
              <b-form-group
                label="Password*"
                label-for="password"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="password"
                      v-model="password"
                      :type="passwordFieldType"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      name="register-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- password -->
              <b-form-group
                label="Repetir password*"
                label-for="password"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="password"
                      v-model="password"
                      :type="passwordFieldType"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      name="register-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              
              <!-- checkbox -->
              <b-form-group class="d-none d-lg-block">
                <b-form-checkbox
                  id="register-privacy-policy"
                  v-model="status"
                  name="checkbox-1"
                >
                  Li e concordo com os <b-link>termos e condições</b-link>
                  de uso e <b-link>políticas de privacidade.</b-link>
                </b-form-checkbox>
              </b-form-group>
            </b-form>

            <b-form
              class="col-md-6 auth-register-form"
              @submit.prevent="validationForm"
            >
              <b-form-group
                label="Empresa"
                label-for="empresa"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Empresa"
                  rules="required|empresa"
                >
                  <b-form-input
                    id="empresa"
                    :state="errors.length > 0 ? false:null"
                    name="register-email"
                    placeholder="Empresa"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group
                label="País*"
                label-for="pais"
              >
                <v-select
                  id="pais"
                  v-model="selectedContry"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="countryName"
                  :selectable="option => ! option.value.includes('select_country')"
                  label="text"
                />
              </b-form-group>

              <b-form-group
                label="Telemóvel*"
                label-for="telemovel"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Telemóvel"
                  rules="required|telemovel"
                >
                  <b-form-input
                    id="telemovel"
                    :state="errors.length > 0 ? false:null"
                    name="telemovel"
                    placeholder="+351 000 000 000"
                  />
                  <!-- <feather-icon
                    icon="CheckCircleIcon"
                    size="16"
                  /> -->
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <b-form-group class="d-block d-lg-none">
                <b-form-checkbox
                  id="register-privacy-policy"
                  v-model="status"
                  name="checkbox-1"
                >
                  Li e concordo com os <b-link>termos e condições</b-link>
                  de uso e <b-link>políticas de privacidade.</b-link>
                </b-form-checkbox>
              </b-form-group>

              <!-- submit button -->
              <div class="register-btn">
                <b-button
                  class="btn-register"
                  type="submit"
                >
                  Register
                </b-button>
              </div>
            </b-form>
          </b-row>
          
        </validation-observer>

        <b-card-text class="login-btn text-center mt-2">
          <b-link :to="{name:'auth-login'}">
            <span>Login</span>
          </b-link>
        </b-card-text>

      </b-card>
    <!-- /Register v1 -->
    </div>
    <b-link class="mercatus-logo">
      <img src="@/assets/images/logo/logo-mercatus-white.png" alt="">
    </b-link>
  </div>

</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, BCol, BCard, BLink, BCardTitle, BCardText, BForm,
  BButton, BFormInput, BFormGroup, BInputGroup, BInputGroupAppend, BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import CostLogo from '@core/layouts/components/Logo.vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    CostLogo,
    // BSV
    BRow,
    BCol,
    BCard,
    BLink,
    BCardTitle,
    BCardText,
    BForm,
    BButton,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    // validations
    ValidationProvider,
    ValidationObserver,
    vSelect
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      selectedContry: 'Portugal',
      countryName: [
        // { value: 'select_country', text: 'Select Country' },
        { value: 'Portugal', text: 'Portugal' },
      ],
      regEmail: '',
      username: '',
      password: '',
      status: '',

      // validation rules
      required,
      email,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    validationForm() {
      this.$refs.registerForm.validate().then(success => {
        if (success) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Form Submitted',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
