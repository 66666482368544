var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v1 px-2"},[_c('b-link',{staticClass:"brand-logo"},[_c('img',{attrs:{"src":require("@/assets/images/logo/logo-dialog.png"),"alt":""}})]),_c('b-card-text',{staticClass:"text-center logo-text"},[_c('span',[_vm._v("Hardware Configuration")])]),_c('div',{staticClass:"auth-inner py-2"},[_c('b-card',{staticClass:"mb-0"},[_c('validation-observer',{ref:"registerForm"},[_c('b-row',[_c('b-form',{staticClass:"col-md-6 auth-register-form",on:{"submit":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label":"Email*","label-for":"email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"email","state":errors.length > 0 ? false:null,"name":"register-email","placeholder":"Diogo@diogo.com"},model:{value:(_vm.regEmail),callback:function ($$v) {_vm.regEmail=$$v},expression:"regEmail"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Password*","label-for":"password"}},[_c('validation-provider',{attrs:{"name":"Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"password","type":_vm.passwordFieldType,"state":errors.length > 0 ? false:null,"name":"register-password","placeholder":"············"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Repetir password*","label-for":"password"}},[_c('validation-provider',{attrs:{"name":"Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"password","type":_vm.passwordFieldType,"state":errors.length > 0 ? false:null,"name":"register-password","placeholder":"············"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"d-none d-lg-block"},[_c('b-form-checkbox',{attrs:{"id":"register-privacy-policy","name":"checkbox-1"},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}},[_vm._v(" Li e concordo com os "),_c('b-link',[_vm._v("termos e condições")]),_vm._v(" de uso e "),_c('b-link',[_vm._v("políticas de privacidade.")])],1)],1)],1),_c('b-form',{staticClass:"col-md-6 auth-register-form",on:{"submit":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label":"Empresa","label-for":"empresa"}},[_c('validation-provider',{attrs:{"name":"Empresa","rules":"required|empresa"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"empresa","state":errors.length > 0 ? false:null,"name":"register-email","placeholder":"Empresa"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"País*","label-for":"pais"}},[_c('v-select',{attrs:{"id":"pais","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.countryName,"selectable":function (option) { return ! option.value.includes('select_country'); },"label":"text"},model:{value:(_vm.selectedContry),callback:function ($$v) {_vm.selectedContry=$$v},expression:"selectedContry"}})],1),_c('b-form-group',{attrs:{"label":"Telemóvel*","label-for":"telemovel"}},[_c('validation-provider',{attrs:{"name":"Telemóvel","rules":"required|telemovel"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"telemovel","state":errors.length > 0 ? false:null,"name":"telemovel","placeholder":"+351 000 000 000"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"d-block d-lg-none"},[_c('b-form-checkbox',{attrs:{"id":"register-privacy-policy","name":"checkbox-1"},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}},[_vm._v(" Li e concordo com os "),_c('b-link',[_vm._v("termos e condições")]),_vm._v(" de uso e "),_c('b-link',[_vm._v("políticas de privacidade.")])],1)],1),_c('div',{staticClass:"register-btn"},[_c('b-button',{staticClass:"btn-register",attrs:{"type":"submit"}},[_vm._v(" Register ")])],1)],1)],1)],1),_c('b-card-text',{staticClass:"login-btn text-center mt-2"},[_c('b-link',{attrs:{"to":{name:'auth-login'}}},[_c('span',[_vm._v("Login")])])],1)],1)],1),_c('b-link',{staticClass:"mercatus-logo"},[_c('img',{attrs:{"src":require("@/assets/images/logo/logo-mercatus-white.png"),"alt":""}})])],1)}
var staticRenderFns = []

export { render, staticRenderFns }